import React, { useState, useEffect } from "react";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { motion } from "framer-motion";

import { AppWrap, MotionWrap } from "../../wrapper";
import { urlFor, client } from "../../client";
import "./Testimonial.scss";
import TensorCerti from "../../assets/tensorflow.jpg";
import { FaArrowUp, FaUpload } from "react-icons/fa";
import { FaArrowUpRightFromSquare } from "react-icons/fa6";

const Testimonial = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [testimonials, setTestimonials] = useState([]);
  const [brands, setBrands] = useState([]);

  const handleClick = (index) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    const query = '*[_type == "testimonials"]';
    const brandsQuery = '*[_type == "brands"]';

    client.fetch(query).then((data) => {
      // setTestimonials(data);

      let reversedDataCertifications = [...data].reverse();
      setTestimonials(reversedDataCertifications);
    });

    client.fetch(brandsQuery).then((data) => {
      setBrands(data);
    });
  }, []);

  const go2link = (index) => () => {
    window.open(testimonials[index].feedback);
  };

  return (
    <div className="fofo">
      {testimonials.length && (
        <div className="momo">
          <div className="app__testimonial-item app__flex">
            <div className="app_testimonial-main">
              <div
                className="app_testimonial-main-img"
                onClick={go2link(currentIndex)}
              >
                <img
                  src={urlFor(testimonials[currentIndex].imageurl)}
                  alt="testimonial"
                  className="certifucate"
                />
                <div
                  className="app_testimonial-main-img-overlay"
                  onClick={go2link(currentIndex)}
                >
                  {/* <FaArrowUp className="icon7" /> */}
                  <FaArrowUpRightFromSquare />
                </div>
              </div>
              <div className="app-testimonial-name">
                <h3>{testimonials[currentIndex].name}</h3>
                <p className="xyz">{testimonials[currentIndex].company}</p>
              </div>
            </div>
          </div>

          <div className="app__testimonial-btns app__flex">
            <div
              className="app__flex"
              onClick={() =>
                handleClick(
                  currentIndex === 0
                    ? testimonials.length - 1
                    : currentIndex - 1
                )
              }
            >
              <HiChevronLeft />
            </div>

            <div
              className="app__flex"
              onClick={() =>
                handleClick(
                  currentIndex === testimonials.length - 1
                    ? 0
                    : currentIndex + 1
                )
              }
            >
              <HiChevronRight />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AppWrap(
  MotionWrap(Testimonial, "app__testimonial"),
  "certificates",
  "app__primarybg"
);

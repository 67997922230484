import React from "react";
import { FaGithub, FaLinkedin, FaTwitter } from "react-icons/fa";

const SocialMedia = () => {
  return (
    <div className="app__social">
      <div>
        <a href="https://github.com/Dhrumit1314" target="_blank">
          <FaGithub />
        </a>
      </div>

      <div>
        <a href="https://huggingface.co/Dhrumit1314" target="_blank">
          <img
            src={process.env.PUBLIC_URL + "/hf-logo.png"}
            alt="hugging_face"
            style={{ width: 20, height: 20 }}
          />
        </a>
      </div>

      <div>
        <a href="https://www.linkedin.com/in/dhrumitpatelds/" target="_blank">
          <FaLinkedin />
        </a>
      </div>

      <div>
        <a href="https://twitter.com/Dhrumit1314" target="_blank">
          <FaTwitter />
        </a>
      </div>
    </div>
  );
};

export default SocialMedia;

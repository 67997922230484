import React, { useEffect, useState } from "react";

import { About, Footer, Header, Skills, Testimonial, Work } from "./container";
import { Navbar } from "./components";

import "./App.scss";
import Loader from "./components/Loader/Loader";

function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);
  return (
    <div className="app">
      {!loading ? (
        <>
          <Navbar />
          <Header />
          <About />
          <Work />
          <Testimonial />
          <Skills />
          <Footer />
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
}

// export default App
// // const App = () => (
// //   const [loading, setLoading] = useState(false);

// // return (
// //   <div className="app">
// //     <Navbar />
// //     <Header />
// //     <About />
// //     <Work />
// //     <Skills />
// //     {/* <Testimonial /> */}
// //     <Footer />
// //   </div>

// // );
// // };

export default App;
